<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M21.107,0H2.737C1.151,0,0,1.163,0,2.765v18.43C0,22.82,1.151,24,2.737,24h18.37
      C22.729,24,24,22.767,24,21.195V2.765C24,1.189,22.757,0,21.107,0z M2.737,1h18.37C22.187,1,23,1.759,23,2.765V7H1V2.765
      C1,1.709,1.698,1,2.737,1z M10,15v-3h4v3H10z M14,16v3h-4v-3H14z M9,15H5v-3h4V15z M15,12h4v3h-4V12z M15,11V8h4v3H15z M14,11h-4V8
      h4V11z M9,11H5V8h4V11z M4,11H1V8h3V11z M4,12v3H1v-3H4z M4,16v3H1v-3H4z M5,16h4v3H5V16z M9,20v3H5v-3H9z M10,20h4v3h-4V20z
      M15,20h4v3h-4V20z M15,19v-3h4v3H15z M20,16h3v3h-3V16z M20,15v-3h3v3H20z M20,11V8h3v3H20z M1,21.195V20h3v3H2.737
      C1.714,23,1,22.258,1,21.195z M21.107,23H20v-3h3v1.195C23,22.224,22.187,23,21.107,23z"
    />
    <path
      d="M7.642,4.897c0.735,0,1.333-0.598,1.333-1.333c0-0.734-0.598-1.332-1.333-1.332
      c-0.734,0-1.332,0.598-1.332,1.332C6.31,4.299,6.907,4.897,7.642,4.897z M7.642,3.232c0.184,0,0.333,0.149,0.333,0.332
      c0,0.184-0.149,0.333-0.333,0.333c-0.183,0-0.332-0.149-0.332-0.333C7.31,3.381,7.458,3.232,7.642,3.232z"
    />
    <path
      d="M16.358,4.897c0.734,0,1.332-0.598,1.332-1.333c0-0.734-0.598-1.332-1.332-1.332s-1.332,0.598-1.332,1.332
      C15.026,4.299,15.624,4.897,16.358,4.897z M16.358,3.232c0.183,0,0.332,0.149,0.332,0.332c0,0.184-0.149,0.333-0.332,0.333
      s-0.332-0.149-0.332-0.333C16.026,3.381,16.176,3.232,16.358,3.232z"
    />
  </svg>
</template>
